import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  SearchInput,
  SelectField,
  SelectInput,
  TextField
} from 'react-admin'

import PhoneField from '../../custom/common/PhoneField'
import choices from './choices'
import OrderListExcelExportButton from './OrderListExcelExportButton'
import DeliveryStatusButton from './DeliveryStatusButton'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const handleActionDone = () => {
  window.location.reload(true)
}

const copyToOrderNumber = (orderNumber) => {
  const dummy = document.createElement('textarea')
  document.body.appendChild(dummy)
  dummy.value = orderNumber
  dummy.select()
  document.execCommand('copy')
  document.body.removeChild(dummy)
}

const Actions = (props) => {
  let rowKey
  const selectedIds = props.selectedIds

  if (selectedIds.length) rowKey = selectedIds[selectedIds.length - 1]
  if (rowKey) {
    const row = props.data[String(rowKey)]
    // eslint-disable-next-line
    let trackingNumber = new Object(row).trackingNumberStr
    trackingNumber = trackingNumber || '-'

    copyToOrderNumber(trackingNumber)
  }

  return (
    <CardActions>
      <DeliveryStatusButton {...props} onDone={handleActionDone} />
      <OrderListExcelExportButton {...props} />
    </CardActions>
  )
}
const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="search" alwaysOn />
    <SelectInput source="status" choices={choices.temporaryStatus} label="결제완료" alwaysOn />
    <DateInput label="시작 날짜" source="startDate" alwaysOn />
    <DateInput label="종료 날짜" source="endDate" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List
      {...props}
      sort={{field: 'createdAt', order: 'DESC'}}
      actions={<Actions />}
      perPage={25}
      filters={<ListFilter />}
      bulkActions={true}
    >
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="orderId" sortable={false} />
        <TextField source="productName" label="상품명" sortable={false} />
        <TextField source="category" label="카테고리" sortable={false} />

        <TextField source="buyerName" sortable={false} />
        <PhoneField source="buyerPhone" sortable={false} />

        <NumberField source="amount" label="주문금액" sortable={false} />
        <NumberField source="point" sortable={false} />
        <NumberField source="deliveryFee" sortable={false} />
        <NumberField source="paidAmount" label="결제금액" sortable={false} />

        <SelectField source="method" choices={choices.methods} sortable={false} />
        <SelectField source="status" choices={choices.temporaryStatus} sortable={false} />
        <TextField source="trackingNumberStr" sortable={false} />

        <DateField source="createdAt" locales="ko" showTime sortable={true} />

        <DateField source="orderDate" label="발주일" locales="ko" showTime sortable={true} />
        <DateField source="sentDate" label="배송출발일" locales="ko" showTime sortable={true} />
        <DateField source="deliveryDate" label="배송완료일" locales="ko" showTime sortable={true} />

        <TextField source="sellerName" label="업체명" sortable={false} />
      </Datagrid>
    </List>
  )
})
