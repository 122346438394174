import localeMessages from './ko'

const messages = {
  ko: () => import('./ko').then((messages) => messages.default)
}

export default (locale) => {
  if (locale === 'ko') {
    return messages[locale]()
  }

  return localeMessages
}
