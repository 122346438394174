import React from 'react'
import {Edit, maxLength, minLength, SimpleForm, TextField, ReferenceField} from 'react-admin'

import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import ImageClickField from '../../custom/common/ImageClickField'
import RichTextInput from 'ra-input-rich-text'

export default (props) => {
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceField source="userId" reference="users" linkType={'show'} label={'리뷰자ID'} basePath="/users">
          <TextField source="name" />
        </ReferenceField>

        <TextField source="id" />
        <ImageClickField label="제품 이미지" addLabel={true} source="images" />

        <TextField source="description" />
        <RichTextInput
          source="replyMessage"
          label="답변 내용"
          toolbar={[[]]}
          validate={[required, minLength(1), maxLength(2000)]}
        />
      </SimpleForm>
    </Edit>
  )
}
