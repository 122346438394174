import moment from 'moment'
import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'

let recentPage
let recentPerPage
let recentOptions

export async function request(type, params) {
  let url = '/sellers/balanceAccount'
  let options = {}

  console.log('> balance >', type, params)

  switch (type) {
    case UPDATE:
    case CREATE:
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      recentPage = page
      recentPerPage = perPage

      if (!(params.filter.hasOwnProperty('startDate') && params.filter.hasOwnProperty('endDate'))) {
        if (params.filter.hasOwnProperty('startDate')) delete params.filter.startDate
        if (params.filter.hasOwnProperty('endDate')) delete params.filter.endDate
      }
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      recentOptions = options
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break

    case GET_ONE:
      url += `/${params.id}`
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    case 'EXCEL':
      options = recentOptions
      options.params.start = 0
      options.params.perPage = 100
      break

    default:
      break
  }

  console.log('> balance > ', type, url, options)
  return {url, ...options}
}

const refundBanks = [
  {code: '02', name: '한국산업은행'},
  {code: '03', name: '기업은행'},
  {code: '04', name: '국민은행'},
  {code: '05', name: '하나은행(구 외환)'},
  {code: '06', name: '국민은행 (구 주택)'},
  {code: '07', name: '수협중앙회'},
  {code: '11', name: '농협중앙회'},
  {code: '12', name: '단위농협'},
  {code: '16', name: '축협중앙회'},
  {code: '20', name: '우리은행'},
  {code: '21', name: '구)조흥은행'},
  {code: '22', name: '상업은행'},
  {code: '23', name: 'SC제일은행'},
  {code: '24', name: '한일은행'},
  {code: '25', name: '서울은행'},
  {code: '26', name: '구)신한은행'},
  {code: '27', name: '한국씨티은행 (구 한미)'},
  {code: '31', name: '대구은행'},
  {code: '32', name: '부산은행'},
  {code: '34', name: '광주은행'},
  {code: '35', name: '제주은행'},
  {code: '37', name: '전북은행'},
  {code: '38', name: '강원은행'},
  {code: '39', name: '경남은행'},
  {code: '41', name: '비씨카드'},
  {code: '45', name: '새마을금고'},
  {code: '48', name: '신용협동조합중앙회'},
  {code: '50', name: '상호저축은행'},
  {code: '53', name: '한국씨티은행'},
  {code: '54', name: '홍콩상하이은행'},
  {code: '55', name: '도이치은행'},
  {code: '56', name: 'ABN암로'},
  {code: '57', name: 'JP모건'},
  {code: '59', name: '미쓰비시도쿄은행'},
  {code: '60', name: 'BOA(Bank of America)'},
  {code: '64', name: '산림조합'},
  {code: '70', name: '신안상호저축은행'},
  {code: '71', name: '우체국'},
  {code: '81', name: '하나은행'},
  {code: '83', name: '평화은행'},
  {code: '87', name: '신세계'},
  {code: '88', name: '신한(통합)은행'},
  {code: '89', name: '케이뱅크'},
  {code: '90', name: '카카오뱅크'},
  {code: '94', name: 'SSG머니(포인트 100% 사용)'},
  {code: '97', name: '카카오 머니'},
  {code: '98', name: '페이코 (포인트 100% 사용)'},
  {code: 'D1', name: '유안타증권(구 동양증권)'},
  {code: 'D2', name: '현대증권'},
  {code: 'D3', name: '미래에셋증권'},
  {code: 'D4', name: '한국투자증권'},
  {code: 'D5', name: '우리투자증권'},
  {code: 'D6', name: '하이투자증권'},
  {code: 'D7', name: 'HMC투자증권'},
  {code: 'D8', name: 'SK증권'},
  {code: 'D9', name: '대신증권'},
  {code: 'DA', name: '하나대투증권'},
  {code: 'DB', name: '굿모닝신한증권'},
  {code: 'DC', name: '동부증권'},
  {code: 'DD', name: '유진투자증권'},
  {code: 'DE', name: '메리츠증권'},
  {code: 'DF', name: '신영증권'},
  {code: 'DG', name: '대우증권'},
  {code: 'DH', name: '교보증권'},
  {code: 'DI', name: '신영증권'},
  {code: 'DJ', name: '키움증권'},
  {code: 'DK', name: '이트레이드'},
  {code: 'DL', name: '솔로몬증권'},
  {code: 'DM', name: '한화증권'},
  {code: 'DN', name: 'NH증권'},
  {code: 'DO', name: '부국증권'},
  {code: 'DP', name: 'LIG증권'}
]

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  console.log('<< Balance : ', data)

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_ONE:
      ret = data
      break

    case 'EXCEL':
    case GET_LIST:
      let index = (recentPage - 1) * recentPerPage + 1
      const rows = data.data

      for (const row of rows) {
        row.id = index
        row.createdAt = moment(row.createdAt).format('L')

        if (row.vbankRefund) {
          const bankCode = row.vbankRefund.bank
          for (const e of refundBanks) {
            if (e.code === bankCode) {
              row.refundAccount = `${e.name}: ${row.vbankRefund.account}`
              break
            }
          }
        }
        row.productSalePrice = String(row.productSalePrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        row.optionSalePrice = String(row.optionSalePrice).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        row.quantity = String(row.quantity).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        row.amount = String(row.amount).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        row.fee = String(row.fee).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        row.feeDiff = String(row.feeDiff).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        row.deliveryFee = String(row.deliveryFee).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        row.userPayment = String(row.userPayment).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        row.balance = String(row.balance).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

        index += 1
      }

      // let headLine = {
      //   id:'',
      //   createdAt: '',
      //   orderId: '총 : ',
      //   productOrderId: '',
      //   trackingNumberStr: String(header.totalCase),
      //   productName: '건', optionName: '',
      //   productSalePrice: String(header.totalProductSalePrice).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //
      //   quantity: String(header.totalQuantity).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   amount: String(header.totalAmount).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   fee: String(header.totalFee).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   feeDiff: String(header.totalFeeDiff).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   userPayment: String(header.userPayment).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   deliveryFee: String(header.totalDelivery).replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      //   balance: String(header.totalBalance).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      // }
      // rows.unshift(headLine)
      ret = data
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }

  console.log('< balance <', type, 'ret->', ret, 'res>', res)
  return ret
}
