import React from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const OrderListExcelExportButton = (props) => {
  const excelExport = async () => {
    const dataArr = [
      [
        'Index',
        '주문번호',
        '상품명',
        '카테고리',
        '옵션명',
        '제품가',
        '옵션가',
        '수량',
        '주문금액',
        '배송비',
        '합계',

        '포인트',
        '결제금액',
        '결제수단',

        '주문자명',
        '연락처',
        'Email',
        '수령자명',
        '수령자연락처',
        '주소',
        '우편번호',
        '주문자메모',
        '주문생성일',
        '주문상태',
        '업체명',
        '옵션ID',
        '송장번호',

        '발주일',
        '배송출발일',
        '배송완료일'
      ]
    ]

    const {
      data: {data}
    } = await dataProvider('EXCEL', 'orders')

    data.forEach((order, index) => {
      try {
        dataArr.push([
          index + 1,
          String(order.id),
          order.productName,
          order.category,
          order.optionName,
          order.productSalePrice,
          order.optionSalePrice,
          order.quantity,
          order.amount,
          order.deliveryFee,
          order.orderSum,

          order.point,
          order.paidAmount, //결제금액
          order.method,

          order.buyerName,
          order.buyerPhone.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
          order.buyerEmail,
          order.receiverName,
          order.receiverTel.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/, '$1-$2-$3'),
          order.address,
          String(order.zipCode),
          order.memo,
          new Date(order.createdAt).toLocaleString(),
          order.status,
          order.sellerName,
          order.optionId,
          order.trackingNumberStr == null ? String('') : String(order.trackingNumberStr),

          order.orderDate != null ? new Date(order.orderDate).toLocaleString() : '',
          order.sentDate != null ? new Date(order.sentDate).toLocaleString() : '',
          order.deliveryDate != null ? new Date(order.deliveryDate).toLocaleString() : ''
        ])
      } catch (e) {
        console.log(e)
      }
    })
    excelDownload(dataArr, '전체 주문 내역서')
  }
  return (
    <Button label="전체 리스트 다운로드" onClick={excelExport}>
      <ImportExportIcon />
    </Button>
  )
}

export default OrderListExcelExportButton
