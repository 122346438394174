import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath, uploadToS3} from './ImageHandler'
import {cutImages} from './products'
import {ImageParse} from './ImageMethod'

export async function request(type, params) {
  let url = '/sellers/sellers'
  let options = {}

  console.log('sellers>', type, params)

  switch (type) {
    case CREATE:
      options.method = 'POST'
      options.data = params.data

      if (options.data.returnPolicy) options = await cutImages(options, 'returnPolicy')
      options.data = await uploadToS3(options.data, 'returnPolicy', 'seller', 'returnPolicy')
      if (options.data.returnPolicy) options.data.returnPolicy = options.data.returnPolicy.map((image) => image.image)

      if (options.data.exchangePolicy) options = await cutImages(options, 'exchangePolicy')
      options.data = await uploadToS3(options.data, 'exchangePolicy', 'seller', 'exchangePolicy')
      if (options.data.exchangePolicy)
        options.data.exchangePolicy = options.data.exchangePolicy.map((image) => image.image)

      delete options.data.images
      break

    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage,
        ids: localStorage.getItem('seller')
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_ONE:
      url += `/${params.id}`

      break
    case UPDATE:
      url += `/${params.id}`
      options.data = params.data
      if (options.data.returnPolicy && !options.data.returnPolicy.length) delete options.data.returnPolicy
      if (options.data.returnPolicy) {
        options = await cutImages(options, 'returnPolicy')
        options.data = await uploadToS3(options.data, 'returnPolicy', 'seller', 'returnPolicy')
        options.data.returnPolicy = options.data.returnPolicy.map((value) => value.image)
        options.data.returnPolicy = options.data.returnPolicy.map((v) => {
          if (v.substring(0, 5) === 'https') v = ImageParse(v, 'seller')
          return v
        })
      }

      if (options.data.exchangePolicy && !options.data.exchangePolicy.length) delete options.data.exchangePolicy
      if (options.data.exchangePolicy) {
        options = await cutImages(options, 'exchangePolicy')
        options.data = await uploadToS3(options.data, 'exchangePolicy', 'seller', 'exchangePolicy')
        options.data.exchangePolicy = options.data.exchangePolicy.map((value) => value.image)
        options.data.exchangePolicy = options.data.exchangePolicy.map((v) => {
          if (v.substring(0, 5) === 'https') v = ImageParse(v, 'seller')
          return v
        })
      }
      options.method = 'PUT'
      break

    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break

    default:
      break
  }

  console.log('>seller', url, options)
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_LIST:
      ret = data
      break

    case GET_ONE:
      if (data.returnPolicy) {
        data.returnPolicy = data.returnPolicy.map((value) => {
          return {image: convertImagePath(value, 'large')}
        })
      }

      if (data.exchangePolicy) {
        data.exchangePolicy = data.exchangePolicy.map((value) => {
          return {image: convertImagePath(value, 'large')}
        })
      }

      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }

  console.log('<sellers', type, ret, typeof ret)
  return ret
}
