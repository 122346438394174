export const ImageParse = (data, resource) => {
  const title = data.split('%2F')
  const title2 = title[3].split('?')
  const title3 = title2[1].split('=')
  data = `images/${resource}/${title3[1]}/${title2[0]}`
  return data
}

export const ImageParseUpdate = (data, resource) => {
  const title = data['image'].split('%2F')
  const title2 = title[3].split('?')
  const title3 = title2[1].split('=')
  data = `images/${resource}/${title3[1]}/${title2[0]}`
  return data
}
