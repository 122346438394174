import React, {useState} from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, Loading} from 'react-admin'
import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const ListExcelExportButton = () => {
  const [loading, setLoading] = useState(false)

  const excelExport = async () => {
    try {
      setLoading(true)
      const data = await dataProvider('EXCEL', 'productReviews')

      const excelData = [
        ['id', '주문번호', '상품번호', '상품명', '평점', '리뷰 내용', '등록자', '리뷰 등록일', '답글일', '전시 상태']
      ]

      const rows = data.data
      for (let i = 0; i < rows.length; i++) {
        const {id, productId, name, rating, description, userId, createdAt, answerDate, onSale} = rows[i]
        excelData.push([
          i + 1,
          String(id),
          String(productId),
          name,
          rating,
          description,
          userId,
          createdAt ? createdAt.split('T')[0] : '',
          answerDate ? answerDate.split('T')[0] : '',
          onSale
        ])
      }

      setLoading(false)
      excelDownload(excelData, `전체 리스트 - ${new Date().toLocaleString()}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="전체 리스트 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <ImportExportIcon />
        )}
      </Button>
    </>
  )
}

export default ListExcelExportButton
