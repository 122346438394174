import React from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate
} from 'react-admin'
import PhoneField from '../../custom/common/PhoneField'
import choices from '../orders/choices'

class UserShow extends React.Component {
  render() {
    return (
      <Show {...this.props}>
        <TabbedShowLayout>
          <Tab label={'주문 내역'}>
            <ArrayField source="orders" addLabel={false}>
              <Datagrid>
                <TextField source="id" sortable={false} />
                <TextField source="productName" label="상품명" sortable={false} />
                <TextField source="category" label="카테고리" sortable={false} />

                <TextField source="buyerName" sortable={false} />
                <PhoneField source="buyerPhone" sortable={false} />

                <NumberField source="amount" label="주문금액" sortable={false} />
                <NumberField source="point" sortable={false} />
                <NumberField source="deliveryFee" sortable={false} />
                <NumberField source="paidAmount" label="결제금액" sortable={false} />

                <SelectField source="method" choices={choices.methods} sortable={false} />
                <SelectField source="status" choices={choices.status} sortable={false} />

                <TextField source="trackingNumberStr" sortable={false} />
                <BooleanField source="isTodayOrder" sortable={false} />

                <DateField source="createdAt" locales="ko" showTime sortable={true} />

                <DateField source="orderDate" label="발주일" locales="ko" showTime sortable={true} />
                <DateField source="sentDate" label="배송출발일" locales="ko" showTime sortable={true} />
                <DateField source="deliveryDate" label="배송완료일" locales="ko" showTime sortable={true} />

                <TextField source="sellerName" label="업체명" sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(UserShow)
