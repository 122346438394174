import React, {useState} from 'react'
import {
  ArrayInput,
  Create,
  FormDataConsumer,
  ImageField,
  maxLength,
  minLength,
  minValue,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import {required, tel, userName, password} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'

export default (props) => {
  const [imageData, setImageData] = useState([])

  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <TextInput source="name" validate={[required, minLength(1), maxLength(100)]} />
        <TextInput source="address" validate={[required]} />
        <ArrayInput source="tels" validate={required}>
          <SimpleFormIterator>
            <TextInput
              label="번호"
              format={(value) => (typeof value === 'object' ? '' : value)}
              validate={[required, tel]}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput source="defaultFee" validate={[required]} />
        <NumberInput source="minimumPrice" defaultValue={0} validate={[required]} />
        <NumberInput source="packingCount" defaultValue={1} validate={[required, minValue(1)]} />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) formData.returnPolicy = imageData

            return (
              <OrderImageInput
                label="배송 정책 이미지"
                placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                multiple
                setState={setImageData}
                source="returnPolicy"
                accept="image/*"
              >
                <ImageField source="image" title="title" />
              </OrderImageInput>
            )
          }}
        </FormDataConsumer>
        <TextInput source="sellerId" validate={[required, userName]} />
        <TextInput source="password" validate={[required, password]} />
      </SimpleForm>
    </Create>
  )
}
