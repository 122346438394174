import axios from 'axios'
import {isNull, omitBy} from 'lodash'

let isRefreshing = false
let refreshSubscribers = []
const instance = axios.create({baseURL: process.env.REACT_APP_URL})

const models = {
  auth: require('./auth'),
  users: require('./users'),
  categories: require('./categories'),
  products: require('./products'),
  events: require('./events'),
  orders: require('./orders'),
  files: require('./files'),
  sellers: require('./sellers'),

  balanceAccount: require('./balanceAccount'),
  productQAs: require('./productQAs'),
  productReviews: require('./productReviews'),
  sellerNotices: require('./sellerNotices')
}

instance.interceptors.response.use((response) => response, tokenErrorHandler)

function subscribeTokenRefresh(cb) {
  refreshSubscribers.push(cb)
}

function onRefreshed(token) {
  refreshSubscribers.forEach((cb) => cb(token))
  refreshSubscribers = []
}

async function tokenErrorHandler(error) {
  try {
    if (error.response.status !== 401) throw error
    const originalRequest = error.config

    const retryOriginalRequest = new Promise((resolve, reject) => {
      subscribeTokenRefresh((token) => {
        originalRequest.headers.authorization = `Bearer ${token}`
        resolve(axios(originalRequest))
      })
    })

    if (!isRefreshing) {
      isRefreshing = true
      const newToken = await refreshToken()
      isRefreshing = false
      onRefreshed(newToken)
    }
    return retryOriginalRequest
  } catch (e) {
    isRefreshing = false
    refreshSubscribers = []
    throw error
  }
}

async function refreshToken() {
  try {
    const accessToken = localStorage.getItem('accessToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const path = '/sellers/auth/refresh'

    const {data} = await axios.post(process.env.REACT_APP_URL + path, {accessToken, refreshToken})
    localStorage.setItem('accessToken', data.accessToken)
    return data.accessToken
  } catch (e) {
    throw e
  }
}

export default function () {
  const httpClient = (options = {}) => {
    if (!options.headers) options.headers = {}
    const token = localStorage.getItem('accessToken')
    if (token) options.headers.authorization = `Bearer ${token}`
    return instance(options)
  }

  return async function (type, resource, params) {
    const resourceModel = models[resource]
    const options = await resourceModel.request(type, omitBy(params, isNull))
    console.log('++++>', type, resource, 'params>', params, 'options>', options)

    try {
      const response = await httpClient(options)
      console.log('<----', type, resource, response)

      return resourceModel.response(response, type, params)
    } catch (e) {
      if (e.response.status === 409 && resource === 'users') {
        throw new Error('배송중이거나 결제완료 입니다.')
      }
      throw e.response
    }
  }
}
