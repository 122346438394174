import React from 'react'
import {connect} from 'react-redux'
import {getResources, MenuItemLink, translate, DashboardMenuItem} from 'react-admin'
import {withRouter} from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import {withStyles} from '@material-ui/core/styles'

const styles = (theme) => ({
  root: {
    width: '100%',
    margin: 0
  },
  heading: {
    margin: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
})
let menus = [
  {
    title: '공지 사항',
    items: ['sellerNotices']
  },
  {
    title: '상품 관리',
    items: ['products']
  },
  {
    title: '주문 관리',
    items: ['orders']
  },
  {
    title: '정산 관리',
    items: ['balanceAccount']
  },
  {
    title: '문의 관리',
    items: ['productQAs']
  },
  {
    title: '리뷰 관리',
    items: ['productReviews']
  }
]

const Menu = withStyles(styles)(({resources, classes, onMenuClick, logout, translate, location}) => {
  const permissions = localStorage.getItem('auth')
  if (permissions === 'manager' || permissions === 'admin') {
    if (menus[1].title === '시스템 관리') {
      menus = [...menus.slice(0, 1), ...menus.slice(2, 12)]
    }
  }
  return menus.map((menu, index) => {
    let items = null
    if (menu.title === 'DashBoard') {
      return <DashboardMenuItem key={index} primaryText={translate(`ra.page.dashboard`)} onClick={onMenuClick} />
    } else {
      items = menu.items.map((item, index) => {
        const resource = resources.find((resource) => resource.name === item)
        if (resource) {
          return (
            <MenuItemLink
              key={index}
              to={`/${resource.name}`}
              primaryText={translate(`resources.${resource.name}.name`)}
              onClick={onMenuClick}
            />
          )
        }
        return <MenuItemLink disabled key={index} to={`/`} primaryText={item + ' (개발)'} onClick={onMenuClick} />
      })

      let defaultExpanded = false
      if (menu.items.find((item) => location.pathname.indexOf(item) > 0)) defaultExpanded = true

      return (
        <ExpansionPanel key={index} defaultExpanded={defaultExpanded}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.heading}>{menu.title}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{display: 'block'}}>{items}</ExpansionPanelDetails>
        </ExpansionPanel>
      )
    }
  })
})

const mapStateToProps = (state) => ({
  resources: getResources(state)
})

export default withRouter(connect(mapStateToProps)(translate(Menu)))
