import React, {useState} from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, Loading} from 'react-admin'

import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const ListExcelExportButton = () => {
  const [loading, setLoading] = useState(false)

  const excelExport = async () => {
    try {
      setLoading(true)

      const excelData = [
        [
          'ID',
          '날짜',
          '대주문번호',
          '상품주문번호',
          '송장번호',
          '상품명',
          '옵션명',
          '제품가',
          '옵션가',
          '수량',
          '주문금액',
          '사용포인트',
          '배송비',
          '실결제금액',
          '수수료',
          '정산금액',
          '배송완료일',
          '환불계좌'
        ]
      ]

      const retRows = await dataProvider('EXCEL', 'balanceAccount')
      let row
      for (let i = 0; i < retRows.data.length; i++) {
        row = retRows.data[i]
        excelData.push([
          row.id,
          row.createdAt,
          String(row.orderId),
          String(row.productOrderId),
          String(row.trackingNumberStr),
          row.productName, //상품명
          row.optionName,
          row.productSalePrice,
          row.optionSalePrice,
          row.quantity,
          row.amount, //주문금액
          row.point,
          row.deliveryFee,
          row.userPayment, //실결제금액
          row.fee,
          row.balance,
          row.deliveryDate != null ? new Date(row.deliveryDate).toLocaleString() : '',
          row.refundAccount
        ])
      }

      setLoading(false)
      excelDownload(excelData, `전체 리스트 - ${new Date().toLocaleString()}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="전체 리스트 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <ImportExportIcon />
        )}
      </Button>
    </>
  )
}

export default ListExcelExportButton
