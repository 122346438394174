import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import TextsmsIcon from '@material-ui/icons/Textsms'
import {change, isSubmitting, submit} from 'redux-form'
import {
  Button,
  fetchEnd,
  fetchStart,
  LongTextInput,
  maxLength,
  required,
  SaveButton,
  showNotification,
  SimpleForm
} from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import {dataProvider} from '../../App'
import {crudGetOne} from 'ra-core'

class OrderMemoButton extends Component {
  state = {
    error: false,
    showDialog: false
  }

  handleClick = () => {
    this.setState({showDialog: true})
  }

  handleCloseClick = () => {
    this.setState({showDialog: false})
  }

  handleSaveClick = () => {
    const {submit} = this.props
    submit('post-quick-create')
  }

  handleSubmit = async (values) => {
    const {fetchStart, fetchEnd, showNotification, dispatchCrudGetOne, basePath, record, selectedIds, onDone} =
      this.props
    fetchStart()
    try {
      await dataProvider('UPDATE_MEMO', 'orders', {
        data: {productOrderIds: selectedIds, ...values},
        id: this.props.record.id
      })
      onDone()
      await dispatchCrudGetOne('orders', record.id, basePath)
      this.setState({showDialog: false})
    } catch (e) {
      showNotification(e.message, 'error')
    } finally {
      fetchEnd()
    }
  }

  render() {
    const {showDialog} = this.state
    const {isSubmitting, selectedIds, record} = this.props

    return record ? (
      <Fragment>
        <Button disabled={!selectedIds.length} label="메모" onClick={this.handleClick}>
          <TextsmsIcon />
        </Button>
        <Dialog fullWidth open={showDialog} onClose={this.handleCloseClick} aria-label="메모">
          <DialogTitle>메모하기</DialogTitle>
          <DialogContent>
            <SimpleForm form="post-quick-create" resource="posts" onSubmit={this.handleSubmit} toolbar={null}>
              <LongTextInput label="메모" source="adminMemo" validate={[required(), maxLength(2000)]} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton saving={isSubmitting} onClick={this.handleSaveClick} />
            <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    ) : (
      ''
    )
  }
}

const mapStateToProps = (state) => ({
  isSubmitting: isSubmitting('post-quick-create')(state)
})

const mapDispatchToProps = {
  change,
  fetchEnd,
  fetchStart,
  showNotification,
  submit,
  dispatchCrudGetOne: crudGetOne
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderMemoButton)
