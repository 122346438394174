import React from 'react'
import {DateField, Show, Tab, TabbedShowLayout, TextField, translate} from 'react-admin'

import ImageClickField from '../../custom/common/ImageClickField'
import {CardActions, ListButton} from 'ra-ui-materialui'
import RichTextField from 'ra-ui-materialui/lib/field/RichTextField'

const ShowActions = ({basePath, data}) => {
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      {/*<EditButton basePath={basePath} record={data}/> */}
    </CardActions>
  )
}

class SellerNoticeShow extends React.Component {
  render() {
    const {...props} = this.props

    return (
      <Show {...props} actions={<ShowActions />}>
        <TabbedShowLayout>
          <Tab label={'기본 정보'}>
            <TextField source="id" label="ID" />
            <TextField source="title" label="제목" />
            <ImageClickField label="이미지" addLabel={true} source="images" src="image" />
            <RichTextField style={{whiteSpace: 'pre-wrap'}} source="content" label="내용" />
            <DateField source="createdAt" label="공지일" locales="ko" showTime />
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(SellerNoticeShow)
