import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  BooleanField,
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  ImageField,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField
} from 'react-admin'

import ProductListExcelExportButton from './ProductListExcelExportButton'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath, props}) => {
  const permissions = localStorage.getItem('auth')
  return (
    <CardActions>
      <ProductListExcelExportButton {...props} />
      {permissions !== 'seller' ? <CreateButton basePath={basePath} /> : null}
    </CardActions>
  )
}

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={props.translate('resources.products.fields.name')} source="search" alwaysOn />
    <ReferenceInput label="카테고리 종류" source="categoryId" reference="categories" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>

    <ReferenceInput label="이벤트 종류" source="eventId" reference="events" alwaysOn perPage={300}>
      <SelectInput optionText="title" />
    </ReferenceInput>
    <ReferenceInput label="업체 종류" source="sellerId" reference="sellers" alwaysOn>
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  console.log(props)

  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <ThumbnailImageField source="titleImagePath" sortable={false} />
        <TextField source="category" sortable={false} />

        {/*<ReferenceField label="이벤트" source="eventIds" reference="events" linkType={'show'}>
                  <TextField source="title"/>
              </ReferenceField>
              */}
        <ReferenceField label="업체 종류" source="sellerId" reference="sellers" linkType={'show'}>
          <TextField source="name" />
        </ReferenceField>

        <TextField source="name" sortable={false} />
        <NumberField source="usePoint" sortable={false} />
        <NumberField source="discountRate" sortable={false} />
        <NumberField source="originalPrice" sortable={false} />
        <NumberField source="price" sortable={false} />
        <BooleanField source="onSale" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
