const methods = [
  {id: 'card', name: '카드'},
  {id: 'vbank', name: '가상계좌'}
]

const status = [
  {id: 'ready', name: '가상계좌 발급'},
  {id: 'paid', name: '결제완료'},
  {id: 'sent', name: '배송출발'},
  {id: 'delivered', name: '배송완료'},
  {id: 'order', name: '발주'},
  {id: 'cancelled', name: '주문취소'},
  {id: 'exchange', name: '교환'},
  {id: 'refund', name: '환불'}
]

const temporaryStatus = [
  {id: 'paid', name: '결제완료'},
  {id: 'sent', name: '배송출발'},
  {id: 'delivered', name: '배송완료'},
  {id: 'order', name: '발주'},
  {id: 'cancelled', name: '주문취소'}
]

const couriers = [
  {id: '04', name: 'CJ대한통운'},
  {id: '06', name: '로젠택배'},
  {id: '08', name: '롯데택배'},
  {id: '01', name: '우체국택배'},
  {id: '05', name: '한진택배'},
  {id: '22', name: '대신택배'},
  {id: '23', name: '경동택배'},
  {id: '24', name: 'CVSnet 편의점택배'},
  {id: '30', name: 'KGL네트웍스'},
  {id: '32', name: '합동택배'},
  {id: '46', name: 'CU편의점택배'},
  {id: '60', name: '현대택배'},
  {id: '62', name: 'KG옐로우캡'},
  {id: '68', name: '포스트박스(GS25)편의점 택배'},
  {id: '64', name: '용마로지스'},
  {id: '66', name: '동부택배'},
  {id: '11', name: '일양로지스'},
  {id: '40', name: '굿투럭'},
  {id: '43', name: '애니트랙'},
  {id: '44', name: 'SLX'},
  {id: '45', name: '호남택배'},
  {id: '52', name: '세방'},
  {id: '53', name: '농협택배'},
  {id: '54', name: '홈픽택배'},
  {id: '56', name: 'KGB택배'},
  {id: '16', name: '한의사랑택배'},
  {id: '17', name: '천일택배'},
  {id: '18', name: '건영택배'},
  {id: '20', name: '한덱스'},
  {id: '58', name: '하이택배'},
  {id: '94', name: '오늘의픽업'}
]

export default {
  methods,
  status,
  temporaryStatus,
  couriers
}
