import {CREATE, DELETE} from 'react-admin'

export function request(type, params) {
  let url = '/sellers/auth'
  const options = {}

  switch (type) {
    case 'LOGIN':
      options.method = 'POST'
      params.data.sellerId = params.data.userName
      delete params.data.userName
      options.data = params.data
      break

    case 'REFRESH':
      url += '/refresh'
      options.method = 'POST'
      options.data = params.data
      break

    case CREATE:
      url += '/register'
      options.method = 'POST'
      options.data = params.data
      break

    case DELETE:
      options.method = 'DELETE'
      break

    default:
      break
  }

  console.log('>login', type, url, options)
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    default:
      if (data) ret = {data}
      break
  }
  console.log('<login', ret)
  return ret
}
