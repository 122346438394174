import {AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK} from 'react-admin'
import {dataProvider} from '../App'

export default async (type, params) => {
  if (type === AUTH_LOGIN) {
    const {userName, password} = params

    const {
      data: {accessToken, refreshToken, auth, seller}
    } = await dataProvider('LOGIN', 'auth', {data: {userName, password}})
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('refreshToken', refreshToken)
    localStorage.setItem('auth', auth)
    localStorage.setItem('seller', seller.id)
    localStorage.setItem('sellerName', seller.name)

    return Promise.resolve()
  }

  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('auth')
    return Promise.resolve()
  }

  if (type === AUTH_ERROR) {
    const {status} = params
    return status === 401 || status === 403 ? Promise.reject() : Promise.resolve()
  }

  if (type === AUTH_CHECK) {
    return Promise.resolve()
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const auth = localStorage.getItem('auth') //@@이거 셀러는 사용하지 않으므로 나중에 수정 필요
    return auth ? Promise.resolve(auth) : Promise.reject()
  }

  return Promise.reject('Unknown method')
}
