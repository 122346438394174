import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {CardActions, Datagrid, DateField, DateInput, Filter, List, TextField} from 'react-admin'

import ListExcelExportButton from './ListExcelExportButton'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})

const Actions = (props) => {
  return (
    <CardActions>
      <ListExcelExportButton {...props} />
    </CardActions>
  )
}

const ListFilter = (props) => (
  <Filter {...props}>
    {/*<SearchInput  source="search" label="상품명 검색" alwaysOn/> */}
    {/*<SelectInput source="period" choices={choices.status} alwaysOn/> */}
    <DateInput source="startDate" label="시작 날짜" ResettableTextField alwaysOn />
    <DateInput source="endDate" label="종료 날짜" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid>
        <TextField source="id" label="ID" sortable={false} />
        <TextField source="createdAt" label="날짜" locales="ko" sortable={false} />

        <TextField source="orderId" label="대주문번호" sortable={false} />
        <TextField source="productOrderId" label="상품주문번호" sortable={false} />
        <TextField source="trackingNumberStr" label="송장번호" sortable={false} />

        <TextField source="productName" label="상품명" sortable={false} />
        <TextField source="optionName" label="옵션명" sortable={false} />

        <TextField source="productSalePrice" label="제품가" sortable={false} />
        <TextField source="optionSalePrice" label="옵션가" sortable={false} />

        <TextField source="quantity" label="수량" sortable={false} />

        <TextField source="amount" label="주문금액" sortable={false} />
        <TextField source="point" label="사용 포인트" sortable={false} />
        <TextField source="deliveryFee" label="배송비" sortable={false} />
        <TextField source="userPayment" label="실결제금액" sortable={false} />

        <TextField source="fee" label="수수료(실결제x3%))" sortable={false} />
        <TextField source="balance" label="정산금액" sortable={false} />

        <DateField source="deliveryDate" label="배송완료일" locales="ko" showTime sortable={false} />
        <TextField source="refundAccount" label="환불계좌" sortable={false} />
      </Datagrid>
    </List>
  )
})
