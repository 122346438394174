import {DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {convertImagePath} from './ImageHandler'

let recentOptions

export function request(type, params) {
  let url = '/sellers/orders'
  let options = {}
  switch (type) {
    case GET_LIST:
      const {page, perPage} = params.pagination

      if (!(params.filter.hasOwnProperty('startDate') && params.filter.hasOwnProperty('endDate'))) {
        if (params.filter.hasOwnProperty('startDate')) delete params.filter.startDate
        if (params.filter.hasOwnProperty('endDate')) delete params.filter.endDate
      }

      const filter = params.filter
      const anyParams = filter.startDate || filter.endDate || filter.sellerId || filter.search
      if (!anyParams && !filter.status) params.filter.status = 'paid'

      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }

      recentOptions = options
      url += `/productOrders`
      break

    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`

      break

    case GET_ONE:
      url += `/${params.id}`
      url += '/productOrderId'
      break

    case UPDATE: {
      const data = {...params.data}
      url += `/${params.id}/orderInfo`
      options.method = 'PUT'
      options.data = data
      break
    }

    case 'UPDATE_STATUS': {
      const data = {...params.data}
      url += `/${params.id}/productOrderStatus`
      options.method = 'PUT'
      options.data = data
      break
    }

    case 'UPDATE_DELIVERY': {
      const data = {...params.data}
      url += `/${params.id}/delivery`
      options.method = 'PUT'
      options.data = data
      break
    }

    case 'UPDATE_PRODUCT_ORDER_DELIVERY': {
      const data = {...params.data}
      url += `/${params.id}/productOrderDelivery`
      options.method = 'PUT'
      options.data = data
      break
    }

    case 'UPDATE_MEMO': {
      const data = {...params.data}
      url += `/${params.id}/memo`
      options.method = 'PUT'
      options.data = data
      break
    }

    case DELETE:
      const data = {...params.data}
      url += `/${params.id}/productOrderId`
      options.method = 'DELETE'
      options.data = data
      break

    case 'DELETE_FORCE': {
      url += `/${params.id}/force`
      options.method = 'DELETE'
      break
    }

    case 'EXCEL':
      url += `/productOrders`

      options = recentOptions
      options.params.start = 0
      options.params.perPage = 100
      break

    default:
      break
  }
  console.log('> orders', url, type, params, options)
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case UPDATE:
      ret = params
      break

    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break

    case GET_LIST:
      const result = []
      const copyData = data.data

      for (const value of copyData) {
        const numbers = []
        for (const products of value.products) {
          numbers.push(`${products.id}${products.optionName}${products.optionId}`)
        }
        result.push({
          ...value,
          productNumber: numbers.join(', ')
        })
      }

      ret = {...data}
      ret.data = result
      break

    case GET_ONE:
      data.products.forEach((row) => (row.titleImagePath = convertImagePath(row.titleImagePath, 'large')))
      ret = {data}
      break

    case DELETE:
      ret = {data: params}
      break

    default:
      if (data) ret = {data}
      break
  }
  console.log('< orders', type, 'ret->', ret, 'res>', res)
  return ret
}
