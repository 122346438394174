import {CREATE, DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'
import {ImageParse} from './ImageMethod'
import {convertImagePath, uploadToS3} from './ImageHandler'

export async function request(type, params) {
  let url = '/sellers/events'
  const options = {}
  switch (type) {
    case UPDATE:
    case CREATE:
      if (type === UPDATE) {
        url += `/${params.id}`
        options.method = 'PUT'
      } else options.method = 'POST'
      options.data = params.data

      const data = {...params.data}

      if (options && options.data && options.data.imagePath) {
        if (options.data.imagePath.image) {
          if (options.data.imagePath.image.substring(0, 5) === 'https') {
            options.data.imagePath.image = ImageParse(options.data.imagePath.image, 'events')
          }
        }
        options.data = await uploadToS3(data, 'imagePath', 'events', 'imagePath')
        const src = options.data.imagePath.image
        options.data.imagePath = ''
        options.data.imagePath = src
        if (options.data.imagePath.substring(0, 5) === 'https') {
          options.data.imagePath = ImageParse(options.data.imagePath, 'events')
        }
      }
      if (!data.content) {
        options.data.content = ' '
      }

      if (!options.data.imagePath) {
        delete options.data.imagePath
      }

      break
    case GET_LIST:
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = {data}
      break
    case GET_ONE:
      if (data.imagePath) {
        // 이미지가 있는 경우에만 imagePath를 convert해서 페이지로 전달함
        data.imagePath = convertImagePath(data.imagePath, 'large')
        if (typeof data.imagePath === 'string') {
          const image = data.imagePath
          data.imagePath = {}
          data.imagePath.image = image
        }
      }
      ret = {data}
      break
    case GET_LIST:
      ret = data
      break
    case DELETE:
      ret = {data: params}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
