import {CardActions, EditButton, ListButton} from 'ra-ui-materialui'
import React from 'react'

export default ({basePath, data}) => {
  const permissions = localStorage.getItem('auth')
  return (
    <CardActions>
      <ListButton basePath={basePath} />
      {permissions !== 'manager' ? <EditButton basePath={basePath} record={data} /> : null}
    </CardActions>
  )
}
