import React, {useState} from 'react'
import {required} from '../../validators'
import CreateActions from '../../custom/common/CreateActions'
import CreateToolbar from '../../custom/common/CreateToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  GET_ONE,
  ImageField,
  ImageInput,
  maxValue,
  minValue,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  LongTextInput
} from 'react-admin'

import Button from '@material-ui/core/Button'
import {dataProvider} from '../../App'

export default (props) => {
  const [data, setData] = useState([])
  const [sellerId, setSellerId] = useState(null)
  const [imageData, setImageData] = useState([])
  const [policyData, setPolicyData] = useState([])
  const [exchangeData, setExchangeData] = useState([])
  const [setDefaultFee] = useState(0)
  const [dragOn, setDragOn] = useState(true)
  const handleDrag = () => {
    dragOn ? setDragOn(false) : setDragOn(true)
  }

  return (
    <Create {...props} actions={<CreateActions />}>
      <SimpleForm toolbar={<CreateToolbar />}>
        <ReferenceInput label="카테고리 종류" source="categoryId" reference="categories" validate={required}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {({formData, ...rest}) => {
            // if (formData.hasOwnProperty('sellerId')) {
            //   if (formData.sellerId !== sellerId) {
            //     setSellerId(formData.sellerId)
            //     dataProvider(GET_ONE, 'sellers', {id: formData.sellerId}).then((seller) => {
            //       setDefaultFee(seller.data.defaultFee)
            //     })
            //   }
            // }
            return (
              <ReferenceInput label="업체 종류" source="sellerId" reference="sellers" perPage={300}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            )
          }}
        </FormDataConsumer>

        <TextInput label="상품명" source="name" validate={required} />
        <LongTextInput
          label="검색 키워드 [콤마(,)로 각 키워드 구분, 최대 10개 가능]"
          source="keyWords"
          validate={[maxValue(200)]}
        />

        <ImageInput source="titleImagePath" accept="image/*" validate={required}>
          <ImageField source="src" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) formData.images = imageData
            if (data.length > 0) formData.detailImages = data
            if (policyData.length > 0) formData.returnPolicy = policyData
            if (exchangeData.length > 0) formData.exchangePolicy = exchangeData

            return (
              <>
                <Button variant="contained" color="primary" onClick={handleDrag}>
                  {dragOn ? 'Delete Mode' : 'Drag Mode'}
                </Button>

                <OrderImageInput
                  label="이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setImageData}
                  source="images"
                  accept="image/*"
                  switch={dragOn}
                  validate={required}
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>

                <OrderImageInput
                  label="상세 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setData}
                  source="detailImages"
                  accept="image/*"
                  switch={dragOn}
                  validate={required}
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>

                <OrderImageInput
                  label="배송 정책 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setPolicyData}
                  source="returnPolicy"
                  accept="image/*"
                  switch={dragOn}
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>

                <OrderImageInput
                  label="교환/환불 정책 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setExchangeData}
                  source="exchangePolicy"
                  accept="image/*"
                  switch={dragOn}
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>
              </>
            )
          }}
        </FormDataConsumer>

        <NumberInput source="originalPrice" validate={[required, minValue(0)]} />
        <NumberInput source="price" label="판매 가격(100원 단위 입력)" validate={[required, minValue(0)]} />
        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (formData.originalPrice > 0 && formData.price > 0)
              formData.discountRate = Math.floor(
                ((formData.originalPrice - formData.price) / formData.originalPrice) * 100
              )
            else formData.discountRate = 0
            return (
              <>
                <NumberInput source="discountRate" label="할인율(소수점 절사)" validate={[required, minValue(0)]} />
              </>
            )
          }}
        </FormDataConsumer>

        <FormDataConsumer>
          {({formData, ...rest}) => {
            return (
              <>
                {<NumberInput source="deliveryFee" label="상품 기본 배송비" validate={[required]} defaultValue={0} />}
              </>
            )
          }}
        </FormDataConsumer>

        <NumberInput
          source="extraFee"
          label="도서 산간 추가 배송비"
          defaultValue={0}
          validate={[required, minValue(0)]}
        />
        <BooleanInput source="packing" defaultValue={true} validate={[required]} />

        <ArrayInput source="options" validate={required}>
          <SimpleFormIterator>
            <TextInput label={'이름'} source="name" validate={required} />
            <NumberInput label={'옵션 추가 금액'} source="price" validate={required} />
            <NumberInput label={'수량'} source="quantity" validate={required} />
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput source="usePoint" validate={[required, minValue(0), maxValue(100)]} />
      </SimpleForm>
    </Create>
  )
}
