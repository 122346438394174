import {DELETE, GET_LIST, GET_MANY, GET_ONE, UPDATE} from 'react-admin'

export function request(type, params) {
  let url = '/sellers/users'
  const options = {}
  switch (type) {
    case GET_LIST:
      if (params.filter && params.filter.referrerId) {
        if (!Number(params.filter.referrerId)) delete params.filter.referrerId
      }
      const {page, perPage} = params.pagination
      const {field, order} = params.sort
      if (!(params.filter.hasOwnProperty('startDate') && params.filter.hasOwnProperty('endDate'))) {
        if (params.filter.hasOwnProperty('startDate')) delete params.filter.startDate
        if (params.filter.hasOwnProperty('endDate')) delete params.filter.endDate
      }
      options.params = {
        ...params.filter,
        sort: field,
        order,
        start: (page - 1) * perPage,
        perPage: params.pagination.perPage
      }
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    case GET_ONE:
      url += `/${params.id}`
      break
    case UPDATE:
      url += `/${params.id}/point`
      options.method = 'PUT'
      const {point, description} = params.data
      options.data = {point, description}
      break
    case DELETE:
      url += `/${params.id}`
      options.method = 'DELETE'
      break
    case 'PUSH':
      url += `/notifications`
      options.data = params.data
      options.method = 'POST'
      break
    case 'MARKETING':
      url += `/${params.data.id}`
      options.method = 'PUT'
      options.data = {agreeMarketing: params.data.agree}
      break
    case 'JUST_LIST':
      break
    default:
      break
  }
  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res
  switch (type) {
    case UPDATE:
      ret = params
      break
    case GET_MANY:
      if (params.ids.length === 1) ret = {data: [data]}
      else ret = data
      break
    case GET_LIST:
      ret = data
      break
    case GET_ONE:
      ret = {data}
      break
    case DELETE:
      ret = {data: params}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
