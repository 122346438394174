import React from 'react'
import 'moment/locale/ko'
import {createBrowserHistory} from 'history'
import {Admin, Resource} from 'react-admin'
import {reducer as tree} from 'ra-tree-ui-materialui'
import authProvider from './providers/authProvider'
import _dataProvider from './providers/data'
import i18nProvider from './providers/i18n'

import Auth from './custom/auth'
import Layout from './custom/Layout'

import {
  users,
  categories,
  events,
  products,
  orders,
  sellers,
  balanceAccount, //정산관리(신)
  productQAs, //문의 관리(신)
  productReviews, //(reviews),  //리뷰 관리(신규)
  sellerNotices
} from './components'

export const history = createBrowserHistory()
export const dataProvider = _dataProvider(process.env.REACT_APP_URL)

const App = () => {
  return (
    <Admin
      locale="en"
      title={process.env.REACT_APP_TITLE}
      appLayout={Layout}
      loginPage={Auth}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      customReducers={{tree}}
      history={history}
    >
      {(permissions) => [
        <Resource name="users" {...users} />,

        <Resource name="sellerNotices" {...sellerNotices} />, //입점공지
        <Resource name="products" {...products} />,
        <Resource name="categories" {...categories} />,
        <Resource name="sellers" {...sellers} />,
        <Resource name="events" {...events} />,

        <Resource name="orders" {...orders} />,
        //새 기능 추가함
        <Resource name="balanceAccount" {...balanceAccount} />, //정산관리
        <Resource name="productQAs" {...productQAs} />, //문의관리(사용자 문의)
        <Resource name="productReviews" {...productReviews} /> //리뷰관리
      ]}
    </Admin>
  )
}

export default App
