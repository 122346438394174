import {withStyles} from '@material-ui/core/styles'
import React from 'react'
import {
  CardActions,
  CreateButton,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SearchInput,
  SelectField,
  TextField
} from 'react-admin'

const styles = (theme) => ({
  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  hiddenOnSmallScreens: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
})
// const ThumbnailImageField = withStyles({image: {height: 60}})(ImageField)

const Actions = ({basePath}) => {
  const permissions = localStorage.getItem('auth')
  return <CardActions>{permissions !== 'manager' ? <CreateButton basePath={basePath} /> : null}</CardActions>
}

const ListFilter = (props) => (
  <Filter {...props}>
    <SearchInput label={props.translate('resources.reports.fields.title')} source="search" alwaysOn />
  </Filter>
)

export default withStyles(styles)(({classes, ...props}) => {
  return (
    <List {...props} actions={<Actions />} perPage={25} filters={<ListFilter />} bulkActions={false}>
      <Datagrid rowClick="show">
        <TextField source="id" sortable={false} />
        <TextField source="title" />
        <TextField source="subTitle" sortable={false} />
        <SelectField
          source="type"
          choices={[
            {id: 'basic', name: '기본'},
            {id: 'doublePoint', name: '포인트 두배'}
          ]}
        />
        <SelectField
          source="showType"
          choices={[
            {id: 'slide', name: '가로'},
            {id: 'grid', name: '세로'}
          ]}
        />
        <NumberField source="order" sortable={false} />
        <DateField source="createdAt" locales="ko" showTime sortable={false} />
      </Datagrid>
    </List>
  )
})
