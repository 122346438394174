import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton} from 'react-admin'
import Button from '@material-ui/core/Button'
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {dataProvider} from '../../App'
import {connect} from 'react-redux'
import OrderDeliveryDialog from './OrderDeliveryDialog'

const styles = (theme) => ({
  dialog: {
    width: '80%'
  },
  button: {}
})

class OrderProductStatusButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isOpenDelivery: false,
      isOpenForce: false,
      value: null
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleDeliveryConfirm = this.handleDeliveryConfirm.bind(this)
    this.handleDeliveryCancel = this.handleDeliveryCancel.bind(this)
  }

  async updateOrder() {
    const {dispatchCrudGetOne, basePath, record, selectedIds} = this.props
    if (this.state.value !== 'delivery') {
      await dataProvider('UPDATE_STATUS', 'orders', {
        id: record.id,
        data: {productOrderIds: selectedIds, status: this.state.value}
      })
      dispatchCrudGetOne('orders', record.id, basePath)
    }
  }

  handleClick() {
    this.setState({
      isOpen: true,
      isOpenDelivery: false,
      value: null
    })
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    const {record, onDone} = this.props
    const {value} = this.state
    if (record.status === value) {
      return this.setState({isOpen: false})
    } else if (value === 'delivery') {
      return this.setState({isOpenDelivery: true})
    } else {
      await this.updateOrder()
    }
    onDone()
    this.setState({isOpen: false})
  }

  async handleDeliveryConfirm(delivery) {
    const {dispatchCrudGetOne, record, basePath, selectedIds, onDone} = this.props
    if (this.state.value === 'delivery') {
      await dataProvider('UPDATE_PRODUCT_ORDER_DELIVERY', 'orders', {
        id: record.id,
        data: {productOrderIds: selectedIds, status: 'sent', ...delivery}
      })
      onDone()
      dispatchCrudGetOne('orders', record.id, basePath)
    }
    this.setState({isOpen: false, isOpenDelivery: false})
  }

  handleDeliveryCancel() {
    this.setState({isOpenDelivery: false})
  }

  handleChange(e, v) {
    this.setState({value: v})
  }

  render() {
    const {isOpen, isOpenDelivery} = this.state
    const {classes, record, selectedIds} = this.props

    const choices = [
      {id: 'order', name: '발주'},
      {id: 'delivery', name: '배송출발'},
      {id: 'delivered', name: '배송완료'}
    ]
    return record.status !== 'cancelled' ? (
      <div>
        <AdminButton disabled={!selectedIds.length} label="배송" onClick={this.handleClick}>
          <FlightTakeoffIcon />
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">배송 상태 변경</DialogTitle>
          <DialogContent>
            <RadioGroup aria-label="Status" name="status" value={this.state.value} onChange={this.handleChange}>
              {choices.map(({id, name}) => (
                <FormControlLabel value={id} key={id} control={<Radio />} label={name} />
              ))}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button onClick={this.handleDone} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
        <OrderDeliveryDialog
          isOpen={isOpenDelivery}
          record={record}
          handleDone={this.handleDeliveryConfirm}
          handleCancel={this.handleDeliveryCancel}
        />
      </div>
    ) : (
      ''
    )
  }
}

export default compose(
  connect(null, {
    dispatchCrudGetOne: crudGetOne,
    dispatchCrudDelete: crudDelete
  }),
  translate,
  withStyles(styles)
)(OrderProductStatusButton)
