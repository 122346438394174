import React from 'react'
import {DateField, NumberField, SelectField, Show, SimpleShowLayout, TextField, translate} from 'react-admin'
import ManagerShowActions from '../../custom/common/ManagerShowActions'
import ImageClickField from '../../custom/common/ImageClickField'

class EventShow extends React.Component {
  render() {
    const {...props} = this.props
    return (
      <Show {...props} actions={<ManagerShowActions />}>
        <SimpleShowLayout>
          <TextField source="id" />
          <TextField source="title" />
          <TextField source="subTitle" />
          <SelectField
            source="type"
            choices={[
              {id: 'basic', name: '기본'},
              {id: 'doublePoint', name: '포인트 두배'}
            ]}
          />
          <SelectField
            source="showType"
            choices={[
              {id: 'slide', name: '가로'},
              {id: 'grid', name: '세로'}
            ]}
          />
          <NumberField source="order" />
          {<ImageClickField label="이미지" addLabel={true} source="imagePath" />}
          <TextField source="handleType" label="핸들타입" />
          <TextField source="content" label="콘텐츠" />

          <DateField source="createdAt" locales="ko" showTime />
        </SimpleShowLayout>
      </Show>
    )
  }
}

export default translate(EventShow)
