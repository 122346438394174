import React from 'react'
import RichTextInput from 'ra-input-rich-text'

import {Edit, maxLength, minLength, SimpleForm, TextField, ReferenceField} from 'react-admin'

import {required} from '../../validators'
import EditActions from '../../custom/common/EditActions'
import EditToolbar from '../../custom/common/EditToolbar'
import ImageClickField from '../../custom/common/ImageClickField'

export default (props) => {
  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <ReferenceField source="questionerId" reference="users" linkType={'show'} label={'문의자ID'} basePath="/users">
          <TextField source="name" />
        </ReferenceField>

        <TextField source="name" label="상품명" />
        <ImageClickField source="images" label="제품 이미지" addLabel={true} />
        <TextField source="question" label="문의 내용" />
        <RichTextInput
          source="answer"
          label="답변 내용"
          toolbar={[[]]}
          validate={[required, minLength(1), maxLength(2000)]}
        />
      </SimpleForm>
    </Edit>
  )
}
