import BookIcon from '@material-ui/icons/Book'
import ReviewCreate from './ReviewCreate'
import ReviewEdit from './ReviewEdit'
import ReviewShow from './ReviewShow'
import ReviewList from './ReviewList'

export default {
  list: ReviewList,
  create: ReviewCreate,
  edit: ReviewEdit,
  show: ReviewShow,
  icon: BookIcon
}
