import React from 'react'
import {connect} from 'react-redux'
import {Layout, Sidebar} from 'react-admin'
import AppBar from './AppBar'
import Menu from './Menu'

const CustomSidebar = (props) => <Sidebar size={200} closedSize={150} {...props} />
const CustomLayout = (props) => <Layout {...props} appBar={AppBar} menu={Menu} sidebar={CustomSidebar} />

const theme = {
  palette: {
    primary: {
      light: '#5f5fc4',
      main: '#303030',
      dark: '#f35d33',
      contrastText: '#fff'
    },
    secondary: {
      light: '#5f5fc4',
      main: '#303030',
      dark: '#f35d33',
      contrastText: '#fff'
    }
  }
}

export default connect(
  (state) => ({
    theme
  }),
  {}
)(CustomLayout)
