import {GET_LIST, GET_MANY} from 'react-admin'
import {convertImagePath} from './ImageHandler'

export function request(type, params) {
  let url = '/sellers/files'
  const options = {}

  switch (type) {
    case 'UPLOAD':
      url += '/upload'
      options.params = params.data
      // options.params.size = 'large'
      break
    case GET_LIST:
      const {productId} = params.filter
      options.params = {productId}
      break
    case GET_MANY:
      if (params.ids.length === 1) url += `/${params.ids[0]}`
      break
    default:
      break
  }

  return {url, ...options}
}

export function response(res, type, params) {
  let ret = {}
  const {data} = res

  switch (type) {
    case GET_LIST:
      data.forEach((image) => {
        image.src = convertImagePath(image.src, 'large')
      })
      ret = {data, total: data.length}
      break
    case GET_MANY:
      data.forEach((image) => {
        image.src = convertImagePath(image.src, 'large')
      })
      ret = {data, total: data.length}
      break
    default:
      if (data) ret = {data}
      break
  }
  return ret
}
