const status = [
  {id: 'today', name: '오늘'},
  {id: '1week', name: '1주일'},
  {id: '2week', name: '2주일'},
  {id: '1month', name: '1개월'}
]

export default {
  status
}
