import React, {useState} from 'react'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import {Button, Loading} from 'react-admin'

import {dataProvider} from '../../App'
import {excelDownload} from '../../custom/common/excelDownload'

const ListExcelExportButton = () => {
  const [loading, setLoading] = useState(false)

  const excelExport = async () => {
    try {
      setLoading(true)

      const data = await dataProvider('EXCEL', 'productQAs')
      const excelData = [['ID', 'seller', 'name', 'optionName', 'question', 'answer', 'questionDate', 'answerDate']]

      const rows = data.data
      for (let i = 0; i < rows.length; i++) {
        const {seller, name, optionName, question, answer, questionDate, answerDate} = rows[i]
        //const getUserInfo = await dataProvider(GET_ONE, 'users', {id: userId})
        //const {name} = getUserInfo.data
        excelData.push([
          i + 1,
          seller,
          name,
          optionName,
          question,
          answer,
          questionDate ? questionDate.split('T')[0] : '',
          answerDate ? answerDate.split('T')[0] : ''
        ])
      }

      setLoading(false)
      excelDownload(excelData, `전체 리스트 - ${new Date().toLocaleString()}.xlsx`)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Button label="전체 리스트 다운로드" onClick={excelExport}>
        {loading ? (
          <div
            style={{
              position: 'fixed',
              top: '0',
              left: '0',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              width: '100%',
              height: '100%'
            }}
          >
            <Loading style={{position: 'relative', zIndex: '1000'}} className="target" />
          </div>
        ) : (
          <ImportExportIcon />
        )}
      </Button>
    </>
  )
}

export default ListExcelExportButton
